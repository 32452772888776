import React from "react";
import CTA from "../../components/Cards/CTA";
import Page from "../../components/Page";
import * as styles from "./recension.module.scss"
import companies from "../../data/bolag"
import { graphql } from "gatsby";

export default function Recensioner({data}) {
  const company = "Fortum";
  return (
    <>
      <Page title={"Recension av " + company}>
        <div className="container px-5 py-6 mx-auto">
          <br />
          <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">Recension av {company}</h1>
          <div className={styles.recension}>
            <p>Om du letar efter en elleverantör som erbjuder fossilfri el är Fortum ett utmärkt val. Alla deras avtal inkluderar el från källor som inte är kolkraft eller olja, så du kan vara säker på att din energianvändning inte bidrar till klimatförändringarna.</p>
            <p>Och om du väljer deras miljöpaket är elen till 100 % från förnybara källor. Dessutom bidrar Fortum med pengar till Håll Sverige Rent vid varje köp. Så om du vill göra din del för miljön är Fortum ett bra alternativ.</p>
            <h2 id="vad-kostar-fortums-elavtal-">Vad kostar Fortums elavtal?</h2>
            <p>Priset på ditt elavtal från Fortum beror på om du väljer ett fast eller rörligt elpris.</p>
            <p>Om du väljer en fast elpris betalar du samma pris per kWh under hela avtalstiden, som vanligtvis är 1-3 år. Om du väljer ett rörligt avtal kommer elpriset att variera från månad till månad.</p>
            <p>Som ny kund kan du få rabatt på 420 kronor (35 kronor/månad), som fördelas över avtalets 12 månader. Även om denna rabatten är rätt så liten det är ändå en trevlig förmån.</p>
            <p>Dessutom får pensionärer en fortlöpande rabatt under hela medlemskapet. Denna rabatt är också ganska liten, men den är ändå ett stort plus.</p>
            <CTA data={companies.fortum} image={data.image} link={"https://www.fortum.se"}/>

            <h2 id="vad-r-f-rdelarna-med-fortum-">Vad är fördelarna med Fortum?</h2>
            <p>Fortums främsta fördel är att de endast säljer el från förnybara källor. Det innebär att du kan vara säker på att din elförbrukning inte skadar miljön.</p>
            <p>Dessutom har Fortum också en rabatt under det första året när du är kund (35 kronor/månad). Det kanske inte låter så mycket, men det är fortfarande en liten bonus.</p>
            <p>Fortum är också ett av det största elbolagen i Sverige vilket det innebär att de har stor erfarenhet och därför är pålitliga. Detta gör också att Fortum kan hålla ett av de lägsta priserna på marknaden.</p>
            <h2 id="vad-r-nackdelarna-med-fortum-">Vad är nackdelarna med Fortum?</h2>
            <p>Den största nackdelen med Fortum är att de inte erbjuder några rabatter för stor elförbrukning. Rabatten som finns gäller dessutom endast under ett år vilket innebär att du senare kommer behöva betala mer.</p>
            <p>Dessutom har det förekommit rapporter om dålig kundservice från Fortum. Det finns många recensioner på Trustpilot som anser att kundservicen kunde förbättras, eller folk som anses att de har blivit dåligt bemötta på olika sätt.</p>
            <p>För dig som är emot kärnkraft behöver du också vara medveten om att det ingår i elen som Fortum säljer. Även om el från kärnkraftverk inte är skadlig för miljön finns det fortfarande människor som känner starkt för denna fråga.</p>
            <h2 id="sammanfattning">Sammanfattning</h2>
            <p>Om du letar efter en elleverantör som erbjuder fossilfri el och en liten rabatt är Fortum ett bra val. Om du använder mycket el kan du dock överväga en annan leverantör.</p>
            <p>Som ny kundkan du få en rabatt på 420 SEK (35 SEK/månad), som fördelas över de 12 avtalsmånaderna.</p>
            <p>Trustpilots recensioner tyder däremot på att kundservicen skulle kunna förbättras.</p>

          </div>
        </div>
      </Page>
    </>
  );
}


export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "fortum.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
 
  }
`;

