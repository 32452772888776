import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function CTA({ data, image, name = "", link }) {
    const info = data.info;
    const pros = data.pros;
    return (
        <div className="bg-gray-50 rounded-xl shadow-md border border-gray-200 p-6 ">
            <div className="flex">
                <div className="text-3xl font-bold ">Elavtalet</div>
                <a href={link} target="_blank norefferer" className="ml-auto bg-blue-700 text-white hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-small px-5 py-2.5">
                    <div className="inline-block align-middle m-auto">
                        Till erbjudande
                    </div>
                </a>
            </div>
            <div className="md:flex mt-4 md:mt-0">
                <GatsbyImage backgroundColor="white" image={image.childImageSharp.gatsbyImageData} alt={name} className="p-2 rounded-xl border-gray-100 shadow-sm border  md:block md:w-60 max-h-44 object-contain" objectFit="contain" />
                <div className="pt-6 md:p-8 text-left space-y-6 w-full">
                    <h3 className="text-xl font-bold">Prisinformation</h3>
                    <div className="grid gap-2 grid-cols-2 justify-center md:grid-cols-4">
                        <div>
                            <h5 className="font-normal text-sm">
                                Avtalsform
                            </h5>
                            <span className="font-bold text-sm">{info.agreement}</span>

                        </div>
                        <div>
                            <h5 className="font-normal text-sm">
                                Uppsägningstid
                            </h5>
                            <span className="font-bold">{info.termination}</span>
                        </div>

                        <div>
                            <h5 className="font-normal text-sm">
                            Bindningstid
                            </h5>
                            <span className="font-bold">{info.bindingTime}</span>
                                                    </div>

                        <div>
                            <h5 className="font-normal text-sm">
                            Månadsavgift
                            </h5>
                            <span className="font-bold">{info.price}</span>
                        </div>

                    </div>

                


        

                </div>

                
        

        
            </div>
            <div className="mt-5 ">
                    <details className="mt-auto">
                        <summary className="font-semibold  bg-white rounded-md py-2 px-4 border  border-gray-100 shadow-sm">
                        Förmåner
                        </summary>
                        <p className="px-4">
                        <ul className="list-disc ">
                            {pros.map((p, i) => {
                                return <li className="text-sm fond-bold" key={"index" + i}>{p}</li>
                            })}
                        </ul>
                        </p>
                    </details>
                   
                    </div>

        </div>

    );
}
